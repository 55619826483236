/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {x} from '@xstyled/styled-components';
import {Article, ScreenContainer, Button} from '../components';
import {Link} from 'gatsby';
import notFoundImageURL from '../images/404.png';
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p"
  }, _provideComponents(), props.components);
  if (!x) _missingMdxReference("x", false);
  if (!x.h1) _missingMdxReference("x.h1", true);
  if (!x.img) _missingMdxReference("x.img", true);
  if (!x.p) _missingMdxReference("x.p", true);
  return React.createElement(Article, {
    style: {
      textAlign: 'center',
      overflow: 'hidden'
    }
  }, React.createElement(ScreenContainer, {
    mt: 5,
    position: "relative"
  }, React.createElement(x.h1, {
    m: 0
  }, "There's a leak in the website."), React.createElement(x.img, {
    alt: "Leak illustration",
    src: notFoundImageURL,
    w: 500,
    h: "auto",
    mx: "auto",
    my: 5
  }), React.createElement(x.p, {
    my: 3,
    mx: "auto",
    fontSize: "1.5em"
  }, React.createElement(_components.p, null, "The page you are looking for does not actually exist.")), React.createElement("div", null, React.createElement(Button, {
    as: Link,
    to: "/"
  }, React.createElement(_components.p, null, "Back to home")))));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
